import React from "react";
import insta from "../../assets/InstaSVG.svg";
import face from "../../assets/FaceSVG.svg";
import logoBranco from "../../assets/logoBranco.png";
import logoTacto from "../../assets/logoTacto.png";

import styled from "@emotion/styled";
import { Container } from "../StyleComponents";
import { Flex } from "@chakra-ui/react";

export const Footer = () => {
  return (
    // <BigDiv bg={colors.lightGreen}>
    <Container>
      <FooterContainer>
        <div>
          <img src={logoBranco} style={{ width: "fit-content" }} alt="logo" />
        </div>
        <div style={{ margin: "1rem 0", textAlign: "center" }}>
          <div>Avenida Dr. Hélio Ribeiro, nº 487 – 8º andar </div>
          <div>
            Centro Político Administrativo - Edifício Concorde, Cuiabá-MT, CEP:
            78.048-250.{" "}
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Flex>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/pelissariconsultoriaoficial"
            >
              <img src={insta} alt="logo" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://web.facebook.com/PelissariConsultoria"
            >
              <img src={face} alt="logo" />
            </a>
          </Flex>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "1.5rem",
            marginTop: "1rem",
            borderTop: "1px solid #BCBCC1",
            width: "100%",
          }}
        >
          <img
            src={logoTacto}
            alt="logo Tacto Sistemas"
            style={{ width: "fit-content" }}
          />

          <StyledSpan>
            Desenvolvido pela Tacto Sistemas 2010©. Todos os direitos
            reservados.
          </StyledSpan>
        </div>
      </FooterContainer>
    </Container>
    // </BigDiv>
  );
};

const FooterContainer = styled.footer`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0 2rem 0;
`;

const StyledSpan = styled.span`
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-left: 1rem;
`;
