import React from "react";
import styled from "@emotion/styled";
import Carousel from "../Carousel";
import fundiarioAgrarioImg from "../../assets/fundiario-agrario.jpg";
import direitoAgronegocioImg from "../../assets/direito-agronegocio.png";
import direitoAmbientalImg from "../../assets/direito-ambiental.png";
import recuperacaoCreditoImg from "../../assets/recuperacaoCredito.png";
import ambientaisFlorestaisImg from "../../assets/ambientais-florestais.jpg";
import assistenciaTecnicaImg from "../../assets/assistencia-tecnica-em-pericias-judiciais-acordos.jpg";
import { H2 } from "../StyleComponents";
import colors from "../../styles/colors";
import { Link } from "react-router-dom";
const mock = [
  {
    img: fundiarioAgrarioImg,
    text: "FUNDIÁRIOS-AGRÁRIOS",
    link: "fundiarios-agrarios",
  },
  {
    img: direitoAgronegocioImg,
    text: "DIREITO DO AGRONEGÓCIO",
    link: "agronegocio",
  },
  {
    img: direitoAmbientalImg,
    text: "DIREITO AMBIENTAL",
    link: "direito-ambiental",
  },
  {
    img: recuperacaoCreditoImg,
    text: "RECUPERAÇÃO DE CRÉDITOS",
    link: "recuperacao-credito",
  },
  {
    img: ambientaisFlorestaisImg,
    text: "AMBIENTAIS-FLORESTAIS",
    link: "ambientais-florestais",
  },
  {
    img: assistenciaTecnicaImg,
    text: "PERÍCIA JUDICIAL E ASSITÊNCIA TÉCNICA",
    link: "pericia-judicial",
  },
];
const AreasAtuacao = () => {
  return (
    <Container id="areas-atuacao">
      <Grid>
        <H2 gridColumn="2/10">Nossas Áreas de Atuação</H2>
      </Grid>
      <ContainerCarousel>
        <Carousel
          items={mock?.map((item, index) => (
            <ContainerCard key={index}>
              <CardCarousel
                img={item?.img}
                text={item?.text}
                link={item?.link}
              />
            </ContainerCard>
          ))}
        />
      </ContainerCarousel>
    </Container>
  );
};
export default AreasAtuacao;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      text-align: center;
      font-size: 1.25rem;
    }
  }
`;
interface CardCarouselProps {
  img: string;
  text: string;
  link?: string;
}

const CardCarousel = ({ img, text, link }: CardCarouselProps) => {
  return (
    <Link to={link!}>
      <Card img={img}>
        <div
          style={{
            cursor: "pointer",
          }}
          className="opac"
        >
          <h3>{text}</h3>
        </div>
      </Card>
    </Link>
  );
};

const Card = styled.div<{ img: string }>`
  color: white;
  font-weight: 700;
  font-size: 24px;
  font-family: "Merryweather", sans-serif;
  width: 384px;
  height: 216px;
  background-size: cover;
  background-image: url(${(props) => props.img});
  position: relative;

  .opac {
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
  }
  h3 {
    width: 60%;
    align-self: center;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    transform: scale(0.65);
  }

  @media (max-width: 680px) {
    transform: scale(0.85);
  }

  :hover {
    border: 3px solid ${colors.brand2};
    color: ${colors.brand2};
  }
`;

const ContainerCard = styled.div`
  padding: 1rem;
`;
const ContainerCarousel = styled.div`
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 95%;
`;
const Container = styled.div`
  max-width: 1440px;
  min-width: 300px;
  margin: 0 auto;
  height: 650px;
`;
