import BigDiv_ from "./BigDiv";
import CallButon_ from "./CallButton";
import CallButtonOutlined_ from "./CallButtonOutlined";
import Container_ from "./Container";
import GradientFilter_ from "./GradientFilter";
import H2Sublinhado_ from "./H2Sublinhado";
import H2_ from "./H2";
import P_ from "./P";
export const BigDiv = BigDiv_;
export const CallButon = CallButon_;
export const CallButtonOutlined = CallButtonOutlined_;
export const Container = Container_;
export const GradientFilter = GradientFilter_;
export const H2Sublinhado = H2Sublinhado_;
export const H2 = H2_;
export const P = P_;
