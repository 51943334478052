import styled from "@emotion/styled";

interface MenuToggleProps {
  active: boolean;
  toggle: () => void;
  ariaLabel: string;
}

const MenuToggle = (props: MenuToggleProps) => {
  return (
    <Button
      aria-label={props.ariaLabel}
      onClick={() => props.toggle()}
      className={props.active ? "toggleButton" : ""}
    >
      <Stick className="stick1" />
      <Stick className="stick2" />
      <Stick className="stick3" />
    </Button>
  );
};

export default MenuToggle;

const Stick = styled.div`
  width: 1.7rem;
  height: 0.1rem;
  background-color: white;
  margin: 2.5px 0;
  transition: 0.4s;
`;
const Button = styled(Stick)`
  position: absolute;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  right: 1.5rem;
  top: 1.5rem;
  flex-direction: column;
  height: 3rem;
  width: 3rem;
  &.toggleButton {
    .stick1 {
      -webkit-transform: rotate(45deg) translate(-2px, -2px);
      transform: rotate(-45deg) translate(-5px, -1px);
    }
    .stick2 {
      opacity: 0;
    }
    .stick3 {
      -webkit-transform: rotate(45deg) translate(-2px, -2px);
      transform: rotate(45deg) translate(-8px, -5px);
    }
  }
  @media (min-width: 850px) {
    display: none;
  }
`;

export const x = 2;
