import colors from "../../styles/colors";
import { Footer } from "../Footer";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import Header from "../Header";
import { useLocation } from "react-router-dom";

function ArtigoPage() {
  const location = useLocation();
  const { state } = location as any;
  const { artigoProps } = state;
  return (
    <>
      <Header hidden />
      <Box bg={colors.lightGreen}>
        <Box p={"1.25rem"} bg="white" margin={"0 auto"} maxW={1200}>
          <Flex justify="center" w="100%">
            <Image src={artigoProps?.img} h={500} w={1200} />
          </Flex>

          <Box textAlign={"center"} py="1.25rem">
            <Heading
              as="h1"
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="44px"
              fontWeight={700}
              m={0}
            >
              {artigoProps?.title}
            </Heading>
          </Box>
          <Box p={8}>
            <Text
              textAlign={"left"}
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="18px"
              fontWeight={400}
            >
              {artigoProps?.text}
            </Text>
          </Box>
          <Box>
            <Text
              textAlign={"left"}
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="18px"
              fontWeight={400}
            >
              <h4>{new Date(artigoProps?.date).toLocaleDateString("Pt-BR")}</h4>
            </Text>
          </Box>
        </Box>

        <Footer />
      </Box>
    </>
  );
}
export default ArtigoPage;
