import styled from "@emotion/styled";

const Container = styled.div`
  max-width: 1200px;
  min-width: 300px;
  margin: 0 auto;

  @media (max-width: 1010px) {
    .sobreGrid {
      h2 {
        grid-column: 2/-1 !important;
        grid-row: 2;
        margin-top: 4rem;
      }
      p {
        grid-column: 2/7 !important;
        padding-right: 1rem;
      }
    }
  }

  @media (max-width: 850px) {
    h1 {
      font-size: 1.5rem;
      width: 80%;
    }
    h2 {
      font-size: 1.5rem;
    }
    button {
    }
    .sobreGrid {
      justify-content: flex-start;
      grid-template-rows: auto auto !important;
      p {
        width: 300px;
        margin-top: -3rem;
        grid-column: 4 /-1 !important;
      }
      h2 {
        grid-column: 5 /-1 !important;
        grid-row: 2;
      }
      button {
        grid-column: 5 /-1 !important;
        width: 129px;
        margin-top: -8rem;
      }
      .balls {
        display: none;
      }
      img {
        display: none;
        grid-column: 1/4;
        grid-row: 2;
        height: auto;
        margin-left: 1rem;
      }
    }
  }
`;

export default Container;
