import styled from "@emotion/styled";
import colors from "../../styles/colors";
import { Container } from "../StyleComponents";
import MenuToggle from "../MenuToggleButton";
import logoBranco from "../../assets/logoBranco.png";
import React from "react";

const Header = ({ hidden = false }: { hidden?: boolean }) => {
  const [active, setActive] = React.useState(false);
  return (
    <ContainerHeader>
      <MenuToggle
        toggle={() => setActive((v) => !v)}
        ariaLabel={""}
        active={active}
      />
      <Container>
        <div className={active ? "active" : "inactive"}>
          <ul>
            <a href="/" style={{ padding: 0 }}>
              <li>
                <img
                  src={logoBranco}
                  alt="logo"
                  style={{ transform: "scale(0.85)" }}
                />
              </li>
            </a>
            {!hidden && (
              <>
                <li>
                  <a href="#sobre-nos">SOBRE NÓS</a>
                </li>
                <li>
                  <a href="#areas-atuacao">ÁREAS DE ATUAÇÃO</a>
                </li>
                <li>
                  <a href="#artigos">ARTIGOS</a>
                </li>
                <li>
                  <a href="#contato" className="contato">
                    contato
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </Container>
    </ContainerHeader>
  );
};

export default Header;
const ContainerHeader = styled.nav`
  position: relative;
  height: 80px;
  z-index: 20000;
  width: 100%;
  color: white;
  font-family: open-suns, suns-serif;
  font-weight: 600;
  text-transform: uppercase;
  background-color: ${colors.brand};
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: white;
    display: block;
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin: 0 0.2rem;
  }
  li {
    height: 80px;
  }
  ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: 30% calc(55% / 4) calc(70% / 4) repeat(
        2,
        calc(70% / 4)
      );
  }
  .contato {
    border: 1px solid white;
    background-color: white;
    color: ${colors.brand};
    max-width: 130px;
    text-align: center;
    height: 46px;
    margin-top: 1rem;
    padding: 1rem;
    transition: 0.4s;
    &:hover {
      background-color: ${colors.brand};
      color: white;
    }
  }
  @media (max-width: 850px) {
    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: fit-content;
      text-align: center;
      align-items: center;
    }
    .active {
      transition: 0.4s;
      background-color: ${colors.brand};
      height: 500px;
    }
    .inactive {
      height: 80px;
      overflow: hidden;
      transition: 0.4s;
    }
  }
`;
