import styled from "@emotion/styled";

const GradientFilter = styled.div`
  position: relative;
  z-index: -10;
  height: 600px;
  &:after {
    z-index: -1;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0);
    pointer-events: none;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7987570028011204) 0%,
      rgba(0, 0, 0, 0.295441176470589) 30%,
      rgba(0, 0, 0, 0) 70%
    );
  }
`;
export default GradientFilter;
