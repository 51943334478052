import styled from "@emotion/styled";
import colors from "../../styles/colors";
import { css } from "@emotion/react";

const H2 = styled.h2<{ gridColumn?: string; gridRow?: string; m?: string }>`
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  font-size: 32px;
  font-family: Merriweather;
  font-weight: 700;
  position: relative;
  color: ${colors.text1};

  ${(props) =>
    props.m &&
    css`
      margin: ${props.m};
    `}
`;

export default H2;
