//função para fazer o get e salvar em um estado
import { useState, useEffect } from "react";

export interface Artigo {
  dataInformativo: Date;
  titulo: string;
  texto: string;
  imagemUrlCompleta: string;
}
const useGetCases = () => {
  const [cases, setCases] = useState<Artigo[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getCases();
        setCases(data);
        setLoading(false);
      } catch (error: any) {
        setError(error);
        setLoading(false);
      }
    };
    getData();
  }, []);
  return { cases, loading, error };
};
export default useGetCases;

async function getCases() {
  const myHeaders = new Headers({
    EmpresaId: "1",
    "Tacto-Grupo-Empresarial": "CCC6D2AC-A612-4654-BA59-784D4D53B283",
    GrupoEmpresaId: "1",
    "Tacto-Grupo-Empresa-Id": "1",
  });
  const response = await fetch(
    "https://site-api.tactonuvem.com.br/v1/site-informativo/ultimos",
    {
      method: "GET",
      headers: myHeaders,
    }
  );
  const data = await response.json();
  return data;
}
