import colors from "../../styles/colors";
import img from "../../assets/direito-agronegocio.png";
import { Footer } from "../Footer";
import { Box, Flex, Heading, Image, List, Text } from "@chakra-ui/react";
import TextItem from "./components/TextItem";
import Header from "../Header";

function Agronegocio() {
  return (
    <>
      <Header hidden />
      <Box bg={colors.lightGreen}>
        <Box bg="white" margin={"0 auto"} maxW={1200}>
          <Flex justify="center" w="100%">
            <Image src={img} h={500} w={1200} />
          </Flex>

          <Box textAlign={"center"} py="1.25rem">
            <Heading
              as="h1"
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="44px"
              fontWeight={700}
              m={0}
            >
              Agronegócio
            </Heading>
          </Box>
          <Box p={8}>
            <Text
              textAlign={"center"}
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="18px"
              fontWeight={400}
            >
              Contamos com assessoria jurídica, consultiva e contenciosa
              relacionada a este importante setor da economia nacional. Nossa
              sede em Mato Grosso, o estado com maior força no agronegócio, a
              PELISSARI CONSULTORIA possui profissionais que conhecem a fundo a
              realidade do setor ao mesmo tempo em que atuam estrategicamente e
              com agilidade para a promoção e crescimento do empreendimento
              rural. Dentre as atividades, destacam-se:
            </Text>
            <List mb={0} spacing={4}>
              <TextItem>
                Planejamento preventivo e assessoria jurídica em operações
                societárias;
              </TextItem>
              <TextItem>
                Elaboração, revisão e negociação de contratos agrários, tais
                como, as diversas formas de parcerias e arrendamentos rurais,
                bem como de contratos e instrumentos atinentes às questões
                relacionadas a propriedade de imóvel rural (incluindo todas as
                questões, consultivas e contenciosas, envolvendo órgãos
                governamentais, autarquias, agências reguladoras e cartórios);
              </TextItem>
              <TextItem>
                Assessoria jurídica em assuntos relacionados à aquisição de
                terras rurais;
              </TextItem>
              <TextItem>
                Elaboração, revisão e negociação de contratos agroindustriais;
                Contratos de compra e venda, novação, transação, entre outros;
                Cobrança, judicial ou extrajudicial, de créditos decorrentes de
                contratos e títulos de crédito ligados ao agronegócio.
              </TextItem>
              <TextItem>
                Elaboração e análise de títulos de crédito ligados ao
                agronegócio, como: Cédula de Produto Rural – CPR, Certificado de
                Depósito Agropecuário – CDA, Warrant Agropecuário – WA, Letra de
                Crédito do Agronegócio – LCA e Certificado de Direitos
                Creditórios do Agronegócio – CDCA entre outros;
              </TextItem>
              <TextItem>
                Elaboração de defesas administrativas e judiciais perante órgãos
                como: JUSTIÇA ESTADUAL E JUSTIÇA FEDERAL, SEMA, IBAMA, INCRA,
                INTERMAT, FUNAI, CONFEA, CREA, ANTT, DER, PRF, INDEA entre
                outros.
              </TextItem>
              <TextItem>
                Assessoria e elaboração de defesas em execução rurais e fiscais;
              </TextItem>
            </List>
          </Box>
        </Box>

        <Footer />
      </Box>
    </>
  );
}
export default Agronegocio;
