import styled from "@emotion/styled";
import colors from "../../styles/colors";
import { BigDiv, H2Sublinhado as H2, Container } from "../StyleComponents";
import useGetCases, { Artigo } from "../../hooks/useGetCases";
import { Key } from "react";
import { Link } from "react-router-dom";

const Artigos = () => {
  const artigosData = useGetCases();
  return (
    <BigDiv bg={colors.brand} id="artigos">
      <Container>
        <Box>
          <H2 m="2rem  auto">Artigos</H2>
          <Flex>
            {artigosData.cases.map(
              (artigo: Artigo, index: Key | null | undefined) => (
                <CardArtigo
                  key={index}
                  img={artigo?.imagemUrlCompleta}
                  title={artigo?.titulo}
                  text={artigo?.texto}
                  date={artigo?.dataInformativo}
                />
              )
            )}
          </Flex>
        </Box>
      </Container>
    </BigDiv>
  );
};
export default Artigos;
interface CardArtigoProps {
  img: string;
  title: string;
  text: string;
  date: Date;
}
const CardArtigo = (props: CardArtigoProps) => {
  return (
    <>
      <Card>
        <img src={props?.img} alt="artigo" />
        <InnerBox>
          <h4>{props?.title}</h4>
          <h5>{new Date(props?.date).toLocaleDateString("Pt-BR")}</h5>
          <Link to="/artigo" state={{ artigoProps: props }}>
            Leia mais
          </Link>
        </InnerBox>
      </Card>
    </>
  );
};
const InnerBox = styled.div`
  padding: 1rem;
  background: white;

  display: flex;
  flex-direction: column;
  height: 164px;
  justify-content: space-between;
  h4 {
    font-family: Merryweather;
    font-weight: 700;
    margin: 0;
  }
  p {
    font-family: Open-sans;
    line-height: 0px;
    padding: 0;
    margin: 0.8rem 0;
    font-weight: 400;
  }
  a {
    font-family: Open-sans;
    color: ${colors.brand};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    color: ${colors.brand};
    text-decoration: none;
  }
`;
const Card = styled.div`
  position: relative;
  height: 320px;
  width: 260px;
  display: grid;
  grid-template-rows: 156px 164px;
  margin: 1rem 1rem;
  z-index: 10;
  background-color: white;

  img {
    max-width: 100%;
    background-size: cover;
  }
  &::after {
    z-index: -1;
    display: block;
    position: absolute;
    content: "";
    z-index: -10;
    height: 320px;
    width: 280px;
    background: #f0f5eb;
    top: -0.8rem;
    right: -0.8rem;
  }
`;

const Box = styled.div`
  /* position: absolute; */
  padding: 3rem 0;
  margin: 0 auto;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;
  flex-wrap: wrap;
`;
