import { Box, Flex } from "@chakra-ui/react";
import { H2, P } from "../StyleComponents";

function SobreNos() {
  return (
    <Flex
      minW={300}
      px={"4rem"}
      py={"2rem"}
      mb={4}
      gap={8}
      id="sobre-nos"
      justifyContent={"space-between"}
      flexDir={["column", "column", "row"]}
    >
      <Box>
        <H2>SOBRE NÓS</H2>
        <P>
          A PELISSARI CONSULTORIA está no mercado a mais de 10 anos. E hoje,
          somos reconhecidos pela eficiência em assessoria de empreendimentos
          rurais e urbanos em todo território nacional. Contamos com uma equipe
          técnica multidisciplinar capacitada com o foco em resultados práticos
          para o agronegócio, por meio da expertise no mercado agrícola,
          florestal, fundiário e ambiental.
        </P>
      </Box>
      <Box>
        <H2>VALORES</H2>
        <P>
          Nosso crescimento depende de nossas atitudes inovadoras, buscando
          sempre estar na vanguarda do conhecimento e formação continuada.
          Mantermos um ambiente de trabalho com respeito mútuo, confiança e
          comprometimento com o resultado, com um time pro-ativo e um espírito
          de família.
        </P>
      </Box>
      <Box>
        <H2>VISÃO</H2>
        <P>
          Ser referência em assessoria agrária e ambiental, superando as
          expectativas e necessidades de nossos clientes, sendo reconhecidos
          pela eficácia e pela ética profissional.
        </P>
      </Box>
    </Flex>
  );
}
export default SobreNos;
