import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";

import FundiariosAgrarios from "./components/AreasAtuacao/FundiariosAgrarios";
import PericiaJudicial from "./components/AreasAtuacao/PericiaJudicial";
import Agronegocio from "./components/AreasAtuacao/Agronegocio";
import DireitoAmbiental from "./components/AreasAtuacao/DireitoAmbiental";
import RecuperacaoCredito from "./components/AreasAtuacao/RecuperacaoCredito";
import AmbientalFlorestal from "./components/AreasAtuacao/AmbientalFlorestal";
import ArtigoPage from "./components/Artigos/ArtigoPage";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="artigo" element={<ArtigoPage />} />
      <Route path="/fundiarios-agrarios" element={<FundiariosAgrarios />} />
      <Route path="/pericia-judicial" element={<PericiaJudicial />} />
      <Route path="/direito-ambiental" element={<DireitoAmbiental />} />
      <Route path="/agronegocio" element={<Agronegocio />} />
      <Route path="/ambientais-florestais" element={<AmbientalFlorestal />} />
      <Route path="/recuperacao-credito" element={<RecuperacaoCredito />} />
      <Route path="/" element={<App />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
