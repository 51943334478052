import styled from "@emotion/styled";
import { ReactNode } from "react";
import Slider from "react-slick";
import prevArrow from "../../assets/prevArrow.svg";
import nextArrow from "../../assets/nextArrow.svg";

const CustomNextArrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  background: white;
  border: 2px solid #148a9c !important;

  text-align: center;
  display: flex;
  height: 3rem;
  width: 5rem;
  position: absolute;
  top: -1rem;
  left: 95%;
  &:hover {
    outline: #148a9c;
  }
`;

const CustomPreviousArrow = styled(CustomNextArrow)`
  left: 85%;
`;
const StyledSlider = styled(Slider)`
  .slick-next {
    &::before {
      display: none;
    }
  }

  .slick-prev {
    &::before {
      display: none;
    }
  }
`;
function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <CustomNextArrow className={className} onClick={onClick}>
      <img src={nextArrow} alt="Proximo slide" />
    </CustomNextArrow>
  );
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <CustomPreviousArrow className={className} onClick={onClick}>
      <img src={prevArrow} alt="Slide Anterior" />
    </CustomPreviousArrow>
  );
}
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3.25,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,

  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        arrows: false,
        initialSlide: 0,
        speed: 500,
      },
    },
  ],
};
interface CarouselProps {
  items: ReactNode[];
}
const Carousel = ({ items }: CarouselProps) => (
  <div>
    <StyledSlider {...settings}>{items}</StyledSlider>
  </div>
);
export default Carousel;
