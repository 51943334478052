import styled from "@emotion/styled";
import colors from "../../styles/colors";
const P = styled.p<{ gridColumn?: string; gridRow?: string }>`
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  font-family: Open-sans;
  font-size: 1.25rem;
  margin-top: 4rem;
  font-weight: 500;
  color: ${colors.text2};
`;

export default P;
