//função para fazer o get e salvar em um estado
import { useState, useCallback } from "react";
interface PayloadContato {
  nome: string;
  email: string;
  telefone: string;
  mensagem: string;
  assunto: string;
}

const usePostContato = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const enviarContato = useCallback(async (payload) => {
    try {
      setLoading(true);
      await postContato(payload);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  }, []);
  return { enviarContato, loading, error };
};
export default usePostContato;

async function postContato(payload: PayloadContato) {
  const myHeaders = new Headers({
    EmpresaId: "1",
    "Tacto-Grupo-Empresarial": "CCC6D2AC-A612-4654-BA59-784D4D53B283",
    GrupoEmpresaId: "1",
    "Tacto-Grupo-Empresa-Id": "1",
    Accept: "application/json",
    "Content-Type": "application/json",
  });
  const response = await fetch(
    "https://site-api.tactonuvem.com.br/v1/fale-conosco",
    {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(payload),
    }
  );
  const data = await response.json();
  return data;
}
