import styled from "@emotion/styled";
import colors from "../../styles/colors";

const CallButon = styled.button<{
  gridColumn?: string;
  gridRow?: string;
  m?: string;
}>`
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  background-color: ${colors.brand};
  padding: 1rem 2rem;
  color: white;
  font-weight: 700;
  cursor: pointer;
  transition: 0.4s;
  height: fit-content;
  min-width: 129px;
  max-height: 80px;
  max-width: 80%;
  margin: ${(props) => props.m};
  &:hover {
    color: ${colors.brand};
    border: 1px solid ${colors.brand};
    background-color: transparent;
  }
`;

export default CallButon;
