import styled from "@emotion/styled";
import colors from "../../styles/colors";
const H2 = styled.h2<{ m?: string }>`
  position: relative;
  justify-self: center;
  align-self: center;
  width: fit-content;
  color: white;
  z-index: 100;
  font-size: 2.75rem;
  font-weight: 700;
  font-family: Merriweather, open-sans;
  text-align: center;
  margin: ${(props) => props.m || "0 auto"};
  &:after {
    position: absolute;
    bottom: -1rem;
    left: 0.9rem;
    content: "";
    display: table;
    height: 2px;
    width: 80%;
    background-color: ${colors.brand2};
  }
`;
export default H2;
