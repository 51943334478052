import styled from "@emotion/styled";
import bg from "./assets/bg.png";
import AD from "./assets/arrowD.svg";
import colors from "./styles/colors";
import AreasAtuacao from "./components/AreasAtuacao";
import Artigos from "./components/Artigos";

import { Container, BigDiv } from "./components/StyleComponents";
import { EntreContato } from "./components/EntreContato";
import { Footer } from "./components/Footer";
import Header from "./components/Header";
import SobreNos from "./components/SobreNos";
import { Flex, Link, Text, Box as ChakraBrox } from "@chakra-ui/react";

export default function Home() {
  return (
    <div style={{ position: "relative" }}>
      <Header />

      <BigDiv bg="red" bgImg={bg} height="600px">
        <Box>
          <Container>
            <BigHeader>
              <h1>
                Precisa de consultoria de confiança? Nós podemos te ajudar
              </h1>
              <a
                id="sobrenos"
                href="#sobre-nos"
                style={{
                  cursor: "pointer",
                  zIndex: 20,
                  pointerEvents: "all",
                }}
              >
                <img src={AD} alt="seta para baixo" />
              </a>

              <Flex
                w={"100%"}
                gap={8}
                justify="center"
                flexDir={["column", "column", "row"]}
                align={["center"]}
              >
                <ChakraBrox>
                  <Text>Ligue agora: (65) 2136-6090</Text>
                </ChakraBrox>
                <Link>
                  <a href="mailto:<nowiki>contato@pelissariconsultoria.com.br?subject=Contato do Site">
                    Clique aqui para nos enviar um e-mail!
                  </a>
                </Link>
              </Flex>
            </BigHeader>
          </Container>
        </Box>
      </BigDiv>

      <SobreNos />
      <AreasAtuacao />
      <Artigos />
      <EntreContato />
      <Footer />
    </div>
  );
}

const Box = styled.div`
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-space-evenly;
    font-family: open-sans;
  }
  .Vdivider {
    width: 1px;
    height: 30px;
    margin: 0 1rem;
    margin-top: 10px;
    background-color: ${colors.brand};
  }
`;

const BigHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 590px;
  position: relative;
  color: white;

  h1 {
    margin-top: -3rem;
    position: relative;
    justify-self: center;
    align-self: center;
    max-width: 760px;
    color: white;
    z-index: 100;
    font-size: 2.75rem;
    font-weight: 700;
    font-family: Merriweather, open-sans;
    text-align: center;
  }

  p {
    font-size: 1rem;
  }
  a#sobrenos {
    position: absolute;
    bottom: 2rem;
  }
`;
