import colors from "../../styles/colors";
import { Footer } from "../Footer";
import { Box, Flex, Heading, Image, List } from "@chakra-ui/react";
import TextItem from "./components/TextItem";
import Header from "../Header";
import img from "../../assets/ambientais-florestais.jpg";

function AmbientalFlorestal() {
  return (
    <>
      <Header hidden />
      <Box bg={colors.lightGreen}>
        <Box bg="white" margin={"0 auto"} maxW={1200}>
          <Flex justify="center" w="100%">
            <Image src={img} h={500} w={1200} />
          </Flex>

          <Box textAlign={"center"} py="1.25rem">
            <Heading
              as="h1"
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="44px"
              fontWeight={700}
              m={0}
            >
              AMBIENTAIS-FLORESTAIS
            </Heading>
          </Box>
          <Box p={8}>
            <List mb={0} spacing={4}>
              <TextItem>Diagnóstico Ambiental; </TextItem>
              <TextItem>CAR – Cadastro Ambiental Rural </TextItem>
              <TextItem>
                APF – Autorização Provisória de Funcionamento Rural;
              </TextItem>
              <TextItem>
                DLA – Declaração de Limpeza de Área para reforma de pastagens;
              </TextItem>
              <TextItem>PRA – Programa de Regularização Ambiental;</TextItem>
              <TextItem>AQC – Autorização para Queima Controlada;</TextItem>
              <TextItem>AD – Autorização para Desmate;</TextItem>
              <TextItem>Projeto de Compensação para Reserva Legal;</TextItem>
              <TextItem>PEF – Plano de Exploração Florestal;</TextItem>
              <TextItem>Laudo Técnico de Tipologia Vegetal;</TextItem>
              <TextItem>
                LP, LI e LO – Licenças autorizativas de atividades ou
                empreendimentos;
              </TextItem>
              <TextItem>Outorga de Captação: Poço e Irrigação;</TextItem>
              <TextItem>
                PGRS – Plano de Gerenciamento de Resíduos Sólidos;
              </TextItem>
              <TextItem>CTF – Cadastro Técnico Federal junto a IBAMA;</TextItem>
              <TextItem>
                CC-SEMA – Cadastro de Consumidores de Produtos de Origem
                Florestal.
              </TextItem>
              <TextItem>
                Mapeamento e Levantamento Aéreo com Utilização de DRONES
              </TextItem>
              <TextItem>EIA/RIMA</TextItem>
            </List>
          </Box>
        </Box>

        <Footer />
      </Box>
    </>
  );
}
export default AmbientalFlorestal;
