import colors from "../../styles/colors";
import { Footer } from "../Footer";
import { Box, Flex, Heading, Image, List, Text } from "@chakra-ui/react";
import TextItem from "./components/TextItem";
import Header from "../Header";
import img from "../../assets/recuperacaoCredito.png";

function RecuperacaoCredito() {
  return (
    <>
      <Header hidden />
      <Box bg={colors.lightGreen}>
        <Box bg="white" margin={"0 auto"} maxW={1200}>
          <Flex justify="center" w="100%">
            <Image src={img} h={500} w={1200} />
          </Flex>

          <Box textAlign={"center"} py="1.25rem">
            <Heading
              as="h1"
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="44px"
              fontWeight={700}
              m={0}
            >
              RECUPERAÇÃO DE CRÉDITOS
            </Heading>
          </Box>
          <Box p={8}>
            <Text
              textAlign={"center"}
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="18px"
              fontWeight={400}
            >
              O escritório PELISSARI CONSULTORIA atua especificamente em
              execuções e cobranças extrajudiciais e judiciais, sendo elas:
            </Text>
            <List mb={0} spacing={4}>
              <TextItem>Ações de cobrança; </TextItem>
              <TextItem>
                Execução de títulos judiciais e extrajudiciais;
              </TextItem>
              <TextItem>Indenizações por dano material e moral;</TextItem>
              <TextItem>Ações Cambiais;</TextItem>
              <TextItem>
                Contratos de compra e venda, novação, transação, entre outros;
                Análise de contratos;
              </TextItem>
              <TextItem>Ações possessórias e despejos.</TextItem>
            </List>
          </Box>
        </Box>

        <Footer />
      </Box>
    </>
  );
}
export default RecuperacaoCredito;
