import colors from "../../styles/colors";
import { Footer } from "../Footer";
import { Box, Flex, Heading, Image, List, Text } from "@chakra-ui/react";
import TextItem from "./components/TextItem";
import Header from "../Header";
import direitoAmbientalImg from "../../assets/direito-ambiental.png";

function DireitoAmbiental() {
  return (
    <>
      <Header hidden />
      <Box bg={colors.lightGreen}>
        <Box bg="white" margin={"0 auto"} maxW={1200}>
          <Flex justify="center" w="100%">
            <Image src={direitoAmbientalImg} h={500} w={1200} />
          </Flex>

          <Box textAlign={"center"} py="1.25rem">
            <Heading
              as="h1"
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="44px"
              fontWeight={700}
              m={0}
            >
              Direito Ambiental
            </Heading>
          </Box>
          <Box p={8}>
            <Text
              textAlign={"center"}
              color={colors.text1}
              fontFamily={"Merriweather"}
              fontSize="18px"
              fontWeight={400}
            >
              Atuamos na área consultiva e contenciosa perante a SEMA/MT, IBAMA,
              INCRA, INTERMAT, FUNAI, JUSTIÇA ESTADUAL E JUSTIÇA FEDERAL
              realizando:
            </Text>
            <List mb={0} spacing={4}>
              <TextItem>
                Defesas e recursos administrativos de Autos de Infração;
              </TextItem>
              <TextItem>Desembargos de áreas;</TextItem>
              <TextItem>
                Assessoria ambiental para expedição de CAR, LAU, LO;
              </TextItem>
              <TextItem>Defesa em Ação Civil Pública;</TextItem>
              <TextItem>
                Defesa em Ação Penal, decorrente de crime ambiental;
              </TextItem>
              <TextItem>Ação individual por danos ambientais;</TextItem>
              <TextItem>
                Atuação judicial ambiental em geral – Ações, Defesas, Recursos,
                Liminares, Mandados de Segurança;
              </TextItem>
              <TextItem>
                Consultas, estudos e pareceres em assuntos relacionados ao Meio
                Ambiente;
              </TextItem>
              <TextItem>
                Assessoria e acompanhamento de Termo de Ajustamento de Conduta
                junto ao Ministério Público ou órgãos ambientais;
              </TextItem>
              <TextItem>
                Apoio técnico para a elaboração de legislação federal, estadual
                e municipal, planos diretores, políticas públicas, zoneamentos
                ecológico-econômicos, sistemas de meio ambiente, dentre outros;
              </TextItem>
              <TextItem>
                Assessoria em Estudo de Impacto Ambiental (EIA) e Relatório de
                Impacto Ambiental (RIMA);
              </TextItem>
              <TextItem>
                Acompanhamento e assessoria nos pedidos de Licenciamento
                Ambiental (Licenças Prévias, de Instalação e de Operação);
              </TextItem>
              <TextItem>Acompanhamento junto a órgãos policiais;</TextItem>
              <TextItem>Sustentação oral nos Tribunais;</TextItem>
              <TextItem>
                Consultoria e Assessoria na área jurídico-ambiental para
                escritórios de advocacia, com ou sem contato direto com os
                clientes;
              </TextItem>
              <TextItem>
                Assessoria em Certificação de Qualidade Ambiental ISO 14.001;
              </TextItem>
              <TextItem>
                Passivos ambientais, adequação e indenizações;
              </TextItem>
              <TextItem>
                Desapropriações e indenizações decorrentes de restrições
                ambientais e criação de Unidades de Conservação, bem como
                análise de sua adequação técnico-jurídica;
              </TextItem>
              <TextItem>Palestras e conferências.</TextItem>
            </List>
          </Box>
        </Box>

        <Footer />
      </Box>
    </>
  );
}
export default DireitoAmbiental;
