import styled from "@emotion/styled";
import colors from "../../styles/colors";
import { css } from "@emotion/react";

const CallButon = styled.button<{
  gridColumn?: string;
  gridRow?: string;
  m?: string;
}>`
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  color: ${colors.brand2};
  border: 1px solid ${colors.brand2};
  background-color: transparent;
  padding: 1rem 2rem;
  font-weight: 600;
  cursor: pointer;
  transition: 0.4s;
  height: 50px;
  &:hover {
    background-color: ${colors.brand};
    color: white;
  }
  ${(props) =>
    props.m &&
    css`
      margin: ${props.m};
    `}
`;

export default CallButon;
