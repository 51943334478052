import React, { useState } from "react";
import colors from "../../styles/colors";

import telCircle from "../../assets/Telefone.svg";
import emailCircle from "../../assets/Email.svg";
import styled from "@emotion/styled";
import { Container, H2, P } from "../StyleComponents";
import CallButon from "../StyleComponents/CallButton";
import usePostContato from "../../hooks/usePostContato";
import { Box, Text } from "@chakra-ui/react";

export const EntreContato = () => {
  const currentDate = new Date();

  const { enviarContato, loading } = usePostContato();
  const [contato, setContato] = useState<any>({
    empresaId: 1,
    dataHora: currentDate.toISOString(),
  });
  const onChange = (name: string, value: any) => {
    setContato({ ...contato, [name]: value });
  };

  return (
    <div
      id="contato"
      style={{
        backgroundColor: colors.lightGreen,
      }}
    >
      <Container>
        <Grid>
          {loading ? (
            <Box>
              <Text>...Carregando</Text>
            </Box>
          ) : (
            <div className="form">
              <FormContainer>
                <div>
                  <Label>Nome Completo</Label>
                  <StyledInput
                    placeholder="Digite seu nome completo"
                    value={contato.nome}
                    onChange={(e) => onChange("nome", e.target.value)}
                  />
                </div>
                <Flex>
                  <div style={{ width: "100%", marginRight: "1rem" }}>
                    <Label>E-mail</Label>
                    <StyledInput
                      type={"email"}
                      placeholder="Digite seu E-mail"
                      value={contato.email}
                      onChange={(e) => onChange("email", e.target.value)}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Label>Telefone</Label>
                    <StyledInput
                      type={"tel"}
                      placeholder="Digite seu telefone"
                      value={contato.telefone}
                      onChange={(e) => onChange("telefone", e.target.value)}
                    />
                  </div>
                </Flex>
                <div>
                  <Label>Assunto</Label>
                  <StyledInput
                    placeholder="Digite o assunto"
                    value={contato.assunto}
                    onChange={(e) => onChange("assunto", e.target.value)}
                  />
                </div>
                <div>
                  <Label>Mensagem</Label>
                  <textarea
                    rows={5}
                    style={{ width: "100%", padding: "0.75rem 0 0.75rem 1rem" }}
                    value={contato.mensagem}
                    onChange={(e) => onChange("mensagem", e.target.value)}
                  />
                </div>
                <CallButon
                  style={{ alignSelf: "flex-end", marginTop: "1rem" }}
                  onClick={() => enviarContato(contato)}
                >
                  Enviar
                </CallButon>
              </FormContainer>
            </div>
          )}

          <H2 gridColumn="2/6" gridRow="3/4">
            Entre em contato
          </H2>
          <P gridColumn="2/6" gridRow="3/-1">
            <div
              style={{ marginTop: 24, display: "flex", alignItems: "center" }}
            >
              <img src={telCircle} alt="telefone" />
              <Text ml={6} fontSize={18}>
                (65) 2136-6090
              </Text>
            </div>
            <div
              style={{ marginTop: 24, display: "flex", alignItems: "center" }}
            >
              <img src={emailCircle} alt="email" />
              <Text ml={6} fontSize={18}>
                contato@pelissariconsultoria.com.br
              </Text>
            </div>
          </P>
        </Grid>
      </Container>
    </div>
  );
};

const FormContainer = styled.div`
  padding: 2rem;
  margin-bottom: 2rem;
  background: white;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.75rem 0 0.75rem 1rem;
`;
const Label = styled.div`
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 1rem 0;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Grid = styled.div`
  display: grid;
  height: 740px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 60px 70px repeat(3, 144px) 80px;

  .form {
    max-width: 100%;
    grid-column: 8/-1;
    grid-row: 2/-1;
    z-index: 200;
  }
  .balls {
    height: 350px;
    width: 270px;
    background-color: black;
    grid-column: 7/10;
    grid-row: 2/2;
  }

  @media (max-width: 1010px) {
    h2 {
      grid-column: 2/-1 !important;
      grid-row: 2;
      margin-top: 4rem;
    }
    p {
      grid-column: 2/7 !important;
      padding-right: 1rem;
    }
  }
  @media (max-width: 850px) {
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;

      p{
        margin-top:1rem;


      }

      h2 {
        margin-top:0;

      }

      .balls {
        display: none;
      }
    }
  }
`;
