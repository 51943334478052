import { Flex, Heading, ListIcon, ListItem } from "@chakra-ui/react";
import colors from "../../../styles/colors";

function TextItem({ children }: { children: string }) {
  return (
    <ListItem>
      <Flex alignItems={"center"} gap={8}>
        <ListIcon>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22Z"
              fill={colors.brand}
            />
            <path
              d="M12.5 7.5C12.5 7.22386 12.7238 7 13 7C13.2761 7 13.5 7.22386 13.5 7.5V9.5H15.5V7.5C15.5 7.22386 15.7238 7 16 7C16.2761 7 16.5 7.22386 16.5 7.5V9.5H18.5V7.5C18.5 7.22386 18.7238 7 19 7C19.2761 7 19.5 7.22386 19.5 7.5V9.5H21.5V7.5C21.5 7.22386 21.7238 7 22 7C22.2761 7 22.5 7.22386 22.5 7.5V9.5H24V12C24 17.52 18.52 22 12 22C5.48 22 0 17.52 0 12C0 6.48 5.48 2 12 2C18.52 2 24 6.48 24 12V12.5H22.5V14.5H19.5V12.5H16.5V14.5H13.5V12.5H12.5V7.5Z"
              fill={colors.brand}
            />
          </svg>
        </ListIcon>
        <Heading as="h3" fontWeight={500}>
          {children}
        </Heading>
      </Flex>
    </ListItem>
  );
}

export default TextItem;
