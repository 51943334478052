import styled from "@emotion/styled";
import { css } from "@emotion/react";

const BigDiv = styled.div<{
  bg?: string;
  bgImg?: string;
  height?: string;
}>`
  height: ${(props) => props.height || "auto"};
  min-height: 640px;
  width: 100%;
  z-index: -1;
  ${(props) =>
    props.bg &&
    css`
      background-color: ${props.bg};
    `}
  ${(props) =>
    props.bgImg &&
    css`
      background-image: url(${props.bgImg});
      background-size: cover;
      background-color: transparent;
    `}
`;

export default BigDiv;
